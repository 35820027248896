import React from "react";
import { Link } from "react-router-dom";
// reactstrap components
import {
  Collapse,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  UncontrolledTooltip,
} from "reactstrap";

function ExamplesNavbar() {
  const [navbarColor, setNavbarColor] = React.useState("navbar-transparent");
  const [collapseOpen, setCollapseOpen] = React.useState(false);
  React.useEffect(() => {
    const updateNavbarColor = () => {
      if (
        document.documentElement.scrollTop > 399 ||
        document.body.scrollTop > 399
      ) {
        setNavbarColor("");
      } else if (
        document.documentElement.scrollTop < 400 ||
        document.body.scrollTop < 400
      ) {
        setNavbarColor("navbar-transparent");
      }
    };
    window.addEventListener("scroll", updateNavbarColor);
    return function cleanup() {
      window.removeEventListener("scroll", updateNavbarColor);
    };
  });
  return (
    <>
      {collapseOpen ? (
        <div
          id="bodyClick"
          onClick={() => {
            document.documentElement.classList.toggle("nav-open");
            setCollapseOpen(false);
          }}
        />
      ) : null}
      <Navbar className={"fixed-top " + navbarColor} color="primary" expand="lg">
        <Container>
          {/*<UncontrolledDropdown className="button-dropdown">*/}
          {/*  <DropdownToggle*/}
          {/*    caret*/}
          {/*    data-toggle="dropdown"*/}
          {/*    href="#pablo"*/}
          {/*    id="navbarDropdown"*/}
          {/*    tag="a"*/}
          {/*    onClick={(e) => e.preventDefault()}*/}
          {/*  >*/}
          {/*    <span className="button-bar"></span>*/}
          {/*    <span className="button-bar"></span>*/}
          {/*    <span className="button-bar"></span>*/}
          {/*  </DropdownToggle>*/}
          {/*  <DropdownMenu aria-labelledby="navbarDropdown">*/}
          {/*    <DropdownItem header tag="a">*/}
          {/*      Dropdown header*/}
          {/*    </DropdownItem>*/}
          {/*    <DropdownItem href="#pablo" onClick={(e) => e.preventDefault()}>*/}
          {/*      Action*/}
          {/*    </DropdownItem>*/}
          {/*    <DropdownItem href="#pablo" onClick={(e) => e.preventDefault()}>*/}
          {/*      Another action*/}
          {/*    </DropdownItem>*/}
          {/*    <DropdownItem href="#pablo" onClick={(e) => e.preventDefault()}>*/}
          {/*      Something else here*/}
          {/*    </DropdownItem>*/}
          {/*    <DropdownItem divider></DropdownItem>*/}
          {/*    <DropdownItem href="#pablo" onClick={(e) => e.preventDefault()}>*/}
          {/*      Separated link*/}
          {/*    </DropdownItem>*/}
          {/*    <DropdownItem divider></DropdownItem>*/}
          {/*    <DropdownItem href="#pablo" onClick={(e) => e.preventDefault()}>*/}
          {/*      One more separated link*/}
          {/*    </DropdownItem>*/}
          {/*  </DropdownMenu>*/}
          {/*</UncontrolledDropdown>*/}
          <div className="navbar-translate">
            <NavbarBrand
                href="/individuals"
                id="navbar-brand-indvs"
            >
              Individual Sessions
            </NavbarBrand>
            <UncontrolledTooltip target="#navbar-brand-indvs">
              One on One
            </UncontrolledTooltip>
            <NavbarBrand
                href="/conscious-rel"
                id="navbar-brand-conrel"
            >
              Conscious Relationships
            </NavbarBrand>
            <UncontrolledTooltip target="#navbar-brand-mas-workshop">
            Grounding the Divine Masculine from Within
          </UncontrolledTooltip>
            <NavbarBrand
                href="/mas-workshop"
                id="navbar-brand-mas-workshop"
            >
              Masculine Workshop
            </NavbarBrand>
            <UncontrolledTooltip target="#navbar-brand-conrel">
              Masculine, Feminine, and Conscious Relationships
            </UncontrolledTooltip>
            <NavbarBrand
              href="/"
              id="navbar-brand-indv"
            >
              Manifestation
            </NavbarBrand>
            <UncontrolledTooltip target="#navbar-brand-indv">
              Manifestation
            </UncontrolledTooltip>
            <NavbarBrand
                href="/sacredunion"
                id="navbar-brand-couples"
            >
              Coaching Programs
            </NavbarBrand>
            <UncontrolledTooltip target="#navbar-brand-couples">
              Individual/Couples Coaching
            </UncontrolledTooltip>
            <button
              className="navbar-toggler navbar-toggler"
              onClick={() => {
                document.documentElement.classList.toggle("nav-open");
                setCollapseOpen(!collapseOpen);
              }}
              aria-expanded={collapseOpen}
              type="button"
            >
              <span className="navbar-toggler-bar top-bar"></span>
              <span className="navbar-toggler-bar middle-bar"></span>
              <span className="navbar-toggler-bar bottom-bar"></span>
            </button>
          </div>
          <Collapse
            className="justify-content-end"
            isOpen={collapseOpen}
            navbar
          >
            {/*<Nav navbar>*/}
            {/*  <NavItem>*/}
            {/*    <NavLink to="/index" tag={Link}>*/}
            {/*      Back to Kit*/}
            {/*    </NavLink>*/}
            {/*  </NavItem>*/}
            {/*  <NavItem>*/}
            {/*    <NavLink href="https://github.com/creativetimofficial/now-ui-kit-react/issues?ref=creativetim">*/}
            {/*      Have an issue?*/}
            {/*    </NavLink>*/}
            {/*  </NavItem>*/}
            {/*  <NavItem>*/}
            {/*    <NavLink*/}
            {/*      href="https://twitter.com/CreativeTim?ref=creativetim"*/}
            {/*      target="_blank"*/}
            {/*      id="twitter-tooltip"*/}
            {/*    >*/}
            {/*      <i className="fab fa-twitter"></i>*/}
            {/*      <p className="d-lg-none d-xl-none">Twitter</p>*/}
            {/*    </NavLink>*/}
            {/*    <UncontrolledTooltip target="#twitter-tooltip">*/}
            {/*      Follow us on Twitter*/}
            {/*    </UncontrolledTooltip>*/}
            {/*  </NavItem>*/}
            {/*  <NavItem>*/}
            {/*    <NavLink*/}
            {/*      href="https://www.facebook.com/CreativeTim?ref=creativetim"*/}
            {/*      target="_blank"*/}
            {/*      id="facebook-tooltip"*/}
            {/*    >*/}
            {/*      <i className="fab fa-facebook-square"></i>*/}
            {/*      <p className="d-lg-none d-xl-none">Facebook</p>*/}
            {/*    </NavLink>*/}
            {/*    <UncontrolledTooltip target="#facebook-tooltip">*/}
            {/*      Like us on Facebook*/}
            {/*    </UncontrolledTooltip>*/}
            {/*  </NavItem>*/}
            {/*  <NavItem>*/}
            {/*    <NavLink*/}
            {/*      href="https://www.instagram.com/CreativeTimOfficial?ref=creativetim"*/}
            {/*      target="_blank"*/}
            {/*      id="instagram-tooltip"*/}
            {/*    >*/}
            {/*      <i className="fab fa-instagram"></i>*/}
            {/*      <p className="d-lg-none d-xl-none">Instagram</p>*/}
            {/*    </NavLink>*/}
            {/*    <UncontrolledTooltip target="#instagram-tooltip">*/}
            {/*      Follow us on Instagram*/}
            {/*    </UncontrolledTooltip>*/}
            {/*  </NavItem>*/}
            {/*</Nav>*/}
          </Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default ExamplesNavbar;
