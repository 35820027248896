import React from "react";

const {Container, Row, Col, Button} = require("reactstrap");
const DynamicContent = require("../../assets/content/DynamicContent.json");

function ExpectationSection() {
    return <div>
                <Row>
                    <Col>
                        <h4>Expectations</h4>
                        <h6>Clients are expected to show up on time for scheduled sessions. 48 hour notice
                            is required for
                            rescheduling. 10 minutes late with no contact will be considered a no show and
                            the session will not
                            be refunded. <br />When purchasing please make sure to use a real email address.
                            You will receive a link to my calendar via email to book your Zoom session.
                            If you have any issues please email me at thewokenmind@gmail.com.
                            <br />You are responsible for you own results based on the level of
                            dedication you put into
                            this work.
                            Coaching is not a replacement for professional mental health or medical
                            services.</h6>
                    </Col>
                </Row>

                <Row>
                    <div className="separator separator-primary"></div>
                </Row>
    </div>
}

export default ExpectationSection;