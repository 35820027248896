import React from "react";

// reactstrap components
import {
    Button,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Container,
    Row,
    Col, Modal, ModalBody,
} from "reactstrap";

// core components
import ExamplesNavbar from "components/Navbars/ExamplesNavbar.js";
import LandingPageHeader from "components/Headers/LandingPageHeader.js";
import DefaultFooter from "components/Footers/DefaultFooter.js";
import CoursesPageHeader from "../../components/Headers/CoursesPageHeader";
import CoursesNavbar from "../../components/Navbars/CoursesNavbar";
import DarkFooter from "../../components/Footers/DarkFooter";
import DynamicContent from "../../assets/content/DynamicContent.json"
import GuideSection from "./GuideSection";
import ExpectationSection from "./ExpectationSection";

function Couples() {
    // const [firstFocus, setFirstFocus] = React.useState(false);
    // const [lastFocus, setLastFocus] = React.useState(false);
    const [modal1, setModal1] = React.useState(false);
    const couplesHeader = "Sacred Union and the New Age";
    const couplesHeaderImg = "couples-header.jpg";

    const screeningButton = function (btnAmount, coupleIndv, courseOption) {
        //coupleIndv 1 for individual, 2 for couples
        //course option ascending from top
        let btnId = "btn" + btnAmount;
        let screeningUrl = "https://cal.com/laura--the-woken-mind-vt0ym7/sacred-union-screening"
        //"https://calendly.com/thewokenmind/coaching-pre-screen?a3=" + coupleIndv + "&a4=" + courseOption;
        return <div><a
            onClick={() => window.Calendly.initPopupWidget({url: screeningUrl})}>
            <button className="btn-round btn-sm" id={btnId}><i
                className="fas fa-dollar-sign"/>{btnAmount}</button>
        </a>
        </div>

    };
    React.useEffect(() => {
        document.body.classList.add("landing-page");
        document.body.classList.add("sidebar-collapse");
        document.documentElement.classList.remove("nav-open");
        window.scrollTo(0, 0);
        document.body.scrollTop = 0;
        return function cleanup() {
            document.body.classList.remove("landing-page");
            document.body.classList.remove("sidebar-collapse");
        };
    }, []);
    return (
        <>
            <CoursesNavbar/>
            <div className="wrapper">
                <CoursesPageHeader headerTitle={couplesHeader} headerJpg={couplesHeaderImg}/>
                <div className="section section-about-us">
                    <Container>

                        {/*<Row>*/}
                        {/*    <Col className="ml-auto mr-auto text-center" md="6">*/}

                        {/*        <h5 className="title">The feminine in the Couple says</h5>*/}
                        {/*        <p className="">*/}
                        {/*            Like attracts like*/}
                        {/*        </p>*/}
                        {/*    </Col>*/}

                        {/*    <Col className="ml-auto mr-auto text-center" md="6">*/}

                        {/*        <h5 className="title">The masculine in the Couple says</h5>*/}
                        {/*        <p className="">*/}
                        {/*            Goals require action*/}
                        {/*        </p>*/}
                        {/*    </Col>*/}
                        {/*</Row>*/}
                        <div className="separator separator-primary"></div>
                        <div className="section-story-overview">

                            <Row>
                                {/*<Col>*/}
                                {/*    <img*/}
                                {/*        alt="..."*/}
                                {/*        className="rounded img-fluid"*/}
                                {/*        src={require("assets/img/fem_sym.jpg").default}*/}
                                {/*        style={{*/}
                                {/*            margin: "auto",*/}
                                {/*            display: "block"*/}
                                {/*        }}*/}
                                {/*    ></img>*/}
                                {/*</Col>*/}
                                <Col className="ml-auto mr-auto text-center" md="6">
                                    <p className="">
                                        The world, as we know it, is coming to an end- we are closing out the Age of
                                        Pisces
                                        and stepping into the Age
                                        of Aquarius.<br/>
                                        Old systems, structures, and institutions are going to continue to break down so
                                        that we can reset and re-
                                        imagine new ways of living and functioning together in communities.<br/>
                                        This time around the goal is to work with the Spiritual realm so that we can
                                        ground
                                        ways of living that allow for
                                        unconditional love, freedom, and respect for all.<br/>
                                        We are shifting out of the heavy, karmic density of the old world and building a
                                        new
                                        world where souls live out
                                        their divine purpose.
                                    </p>
                                </Col>
                                {/*    <Col>*/}
                                {/*        <img*/}
                                {/*            alt="..."*/}
                                {/*            className="rounded img-fluid"*/}
                                {/*            src={require("assets/img/mas_sym.jpg").default}*/}
                                {/*            style={{*/}
                                {/*                margin: "auto",*/}
                                {/*                display: "block",*/}
                                {/*                height: 175*/}
                                {/*            }}*/}
                                {/*        ></img>*/}
                                {/*    </Col>*/}
                                {/*</Row>*/}
                                {/*<Row>*/}
                                {/*<Col>*/}
                                {/*    <img*/}
                                {/*        alt="..."*/}
                                {/*        className="rounded img-fluid img-raised"*/}
                                {/*        src={require("assets/img/pom.jpg").default}*/}
                                {/*        style={{*/}
                                {/*            margin: "auto",*/}
                                {/*            display: "block",*/}
                                {/*            height: 450*/}
                                {/*        }}*/}
                                {/*    ></img>*/}
                                {/*</Col>*/}
                                <Col>
                                    <div
                                        className="image-container image-left"
                                        style={{
                                            backgroundImage:
                                                "url(" + require("assets/img/pom.jpg").default + ")",
                                        }}
                                    ></div>
                                </Col>
                            </Row>

                            <Row>
                                {/*<Col md="6">*/}
                                {/*    <div*/}
                                {/*        className="image-container image-left"*/}
                                {/*        style={{*/}
                                {/*            backgroundImage:*/}
                                {/*                "url(" + require("assets/img/stone-wall.jpeg").default + ")",*/}
                                {/*        }}*/}
                                {/*    >*/}
                                {/*    </div>*/}
                                {/*</Col>*/}
                                {/*<Col>*/}
                                {/*        <p className="blockquote blockquote-info-inv" style={{top: 125, left: 70}}>*/}
                                {/*            Why is it that so many artists, athletes, innovators, creators,*/}
                                {/*            entrepreneurs, are so*/}
                                {/*            magnetic? It doesn’t have to just do with their appearance, wealth, or*/}
                                {/*            status, these are all*/}
                                {/*            superficial traits- it is their internal union frequency.*/}
                                {/*        </p>*/}
                                {/*</Col>*/}
                                <Col md="5">
                                    <div
                                        className="image-container image-right"
                                        style={{
                                            backgroundImage:
                                                "url(" + require("assets/img/trees.jpg").default + ")",
                                            height: 700
                                        }}
                                    ></div>
                                </Col><Col md="5">
                                <h3>Relationships: Balancing your Masculine and Feminine Energy</h3>
                                <p>
                                    Masculine and Feminine energy is the framework for duality. You can see it as
                                    the intricate balance between
                                    the heart and head; soul and ego; spiritual and physical; or right and left
                                    hemispheres of the brain. When
                                    these energies are in conflict with each other within ourselves this will also
                                    show up in our external reality.<br/>
                                    Through both self-reflection and reflecting on our relationships with others,
                                    we can gain clarity on what we
                                    need to work on to bring healing, awareness, and balance to how our Masculine
                                    and Feminine energies
                                    interact with each other.<br/>

                                    The key is to come into an inner union (the alchemical marriage) with self so
                                    that you can release co-
                                    dependency and step fully into your authenticity, sovereignty, and purpose.
                                </p>
                            </Col>
                            </Row>
                            <Row>
                                <Col md="5">
                                    <h3>Conscious Leaders</h3>
                                    <p>Those that are leading the way with balancing their own Masculine and Feminine
                                        energies will be serving
                                        humanity as they create the new businesses, services, and structures in the Age
                                        of Aquarius.<br/>

                                    </p>
                                    <ul>
                                        <li>The Hero’s Journey: building/shifting wealth, creation of new systems,
                                            and conscious leadership
                                        </li>
                                        <li> The Heroine’s Journey: reprogramming the subconscious mind, healing the
                                            emotional body, and teaching
                                        </li>
                                    </ul>
                                </Col>
                                <Col md="5">
                                    <div
                                        className="image-container image-left"
                                        style={{
                                            backgroundImage:
                                                "url(" + require("assets/img/columns.jpg").default + ")",
                                            height: 550
                                        }}
                                    ></div>
                                </Col>

                            </Row>

                            <Row>
                                <Col md="5">
                                    <div
                                        className="image-container image-right"
                                        style={{
                                            backgroundImage:
                                                "url(" + require("assets/img/wheat.jpg").default + ")",
                                            height: 550
                                        }}
                                    ></div>
                                </Col>
                                <Col>
                                    <h3>The New Power Couple</h3>
                                    <p>Together the Divine Masculine and Divine Feminine collectives will be building
                                        outside of the
                                        codependent constructs created from the old paradigm.<br/>
                                        Their journeys begin with deconstructing their own karmic patterns,
                                        codependency, and belief systems so
                                        that are able to establish a fresh clean slate to walk in their unique purpose-
                                        honoring their innate talents
                                        and intuitive wisdom.<br/>
                                        As the Masculine and Feminine continue to ground their authentic purpose many
                                        will come together in
                                        conscious relationships. These new “power couples” will redefine what success
                                        looks like; demonstrating
                                        a level of integrity that we have not seen before in our leaders.</p>
                                </Col>
                            </Row>
                            <Row><Col>
                                <h2>Who this program is for?</h2>
                                <ul>
                                    <li>Awakened Masculines and Feminines who are devoted to healing their own
                                        wounds and serving others
                                    </li>
                                    <li>Awakened Masculines and Feminines who understand that any outer union
                                        must begin with union of their
                                        own energies
                                    </li>
                                    <li>Awakened Masculines and Feminines who are working towards their purpose
                                    </li>
                                    <li>Awakened Masculines and Feminines who are leading the way on the path of
                                        free thinking and sovereignty
                                    </li>
                                    <li>Conscious Couples that are currently working together to ground a
                                        sacred, interdependent union
                                    </li>
                                </ul>
                            </Col><Col md="5">
                                <div
                                    className="image-container image-left"
                                    style={{
                                        backgroundImage:
                                            "url(" + require("assets/img/cubes.jpg").default + ")",
                                        height: 500
                                    }}
                                ></div>
                            </Col>

                            </Row>
                            <Row><Col>
                                <br/>
                                <h2>Sacred Union Program</h2>
                            </Col></Row>
                            <Row><Col>
                                <div className="blockquote-info-inv">Select an option below and click the <button
                                    className="btn-round btn-sm" id={"btnExample"}><i
                                    className="fas fa-dollar-sign"/>{"--"}</button> button to schedule a call with me to
                                    review your selected program.
                                </div>
                                <br/>
                            </Col></Row>

                            <Row><Col>
                                <h3>Individual - {screeningButton(675, 1, 1)}</h3>
                            </Col><Col>
                                <h3>Couples - {screeningButton(800, 2, 1)}</h3>
                            </Col> </Row>
                            <Row>
                                <Col><b>Week 1:</b><br/>The Remembrance- deprogramming from the old belief systems and
                                    karmic patterns, people, and situations holding you back from fully embodying your
                                    authentic self</Col>
                                <Col><b>Week 1:</b><br/> Addressing Lingering Codependency and Old Patterns in the
                                    Connection
                                </Col>
                            </Row>
                            <Row>
                                <Col><b>Week 2:</b><br/> Dark and Light Integration- harnessing the power of the Higher
                                    Self to
                                    transmute the shadow,
                                    become the master of the darkness, and focus on forward movement
                                </Col>
                                <Col><b>Week 2:</b><br/> Shadow Work- Handling Triggers and Conscious Dialogue
                                </Col>
                            </Row>
                            <Row>
                                <Col><b>Week 3:</b><br/> Reflection- looking at you current experiences and
                                    relationships-what
                                    are they telling you?
                                </Col>
                                <Col><br/><b>Week 3:</b><br/> Masculine and Feminine Polarity
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <b>Week 4:</b><br/> Application and Practice- action steps, lifestyle changes, plans
                                    for the
                                    future
                                </Col>
                                <Col>
                                    <b>Week 4:</b><br/> Interdependence- focusing on your purpose

                                </Col>
                            </Row>
                            <Row>
                                <div className="separator separator-primary"></div>
                            </Row>
                            <Row>
                                <div className="separator separator-primary"></div>
                            </Row>
                            <Row>
                                <Col>
                                    <br/>
                                    <br/>
                                    <br/>
                                    <div className="shadow-lg category text-center">
                                        <h3>Free Format Style Coaching</h3>
                                    </div>
                                </Col>
                            </Row>

                            <Row><Col>
                                <h4>Couples Coaching<br/> 4 weeks {screeningButton(800, 2, 2)}</h4>
                                <p> 1 hr long sessions to address the current themes, challenges, and opportunities
                                    for growth coming up in
                                    your relationship.
                                </p>
                            </Col><Col>
                                <h4>Birthing and Supporting Your Purpose<br/>4 weeks {screeningButton(675, 1, 2)}</h4>
                                <p>1 hr long sessions to support the discovery, launch, and continuous development
                                    of your soul’s mission
                                    work.</p>
                            </Col>
                            </Row>

                            {/*<Row><Col className="text-center">*/}
                            {/*    <br/>*/}
                            {/*    <h3>1 hr Single Coaching Session</h3>*/}
                            {/*    <h4>*/}
                            {/*        New Clients {screeningButton(185, 1)}<br/>*/}
                            {/*        Returning Clients {screeningButton(155, 1)}*/}
                            {/*    </h4>*/}

                            {/*</Col></Row>*/}

                            <ExpectationSection />

                        </div>
                    </Container>
                </div>

                <GuideSection/>
                {/*<div className="section section-contact-us text-center">*/}
                {/*    <Container>*/}
                {/*        <h2 className="title">Interested in the Program?</h2>*/}
                {/*        <p className="">Get more info and sign up.</p>*/}

                {/*    </Container>*/}
                {/*</div>*/}
                <DarkFooter/>
            </div>
        </>
    );
}

export default Couples;
