import React from "react";

// reactstrap components
import {
    Button,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Container,
    Row,
    Col, Modal, ModalBody,
} from "reactstrap";

import patternsImg from '../../assets/img/patterns.jpg';
import awarenessImg from "../../assets/img/mirror_awareness.jpg";
import reflectionImg from "../../assets/img/heal_reflection.jpg";
import loveDeeplyImg from "../../assets/img/love_deeply.jpg"



// core components
import ExamplesNavbar from "components/Navbars/ExamplesNavbar.js";
import LandingPageHeader from "components/Headers/LandingPageHeader.js";
import DefaultFooter from "components/Footers/DefaultFooter.js";
import CoursesPageHeader from "../../components/Headers/CoursesPageHeader";
import CoursesNavbar from "../../components/Navbars/CoursesNavbar";
import DarkFooter from "../../components/Footers/DarkFooter";
import DynamicContent from "../../assets/content/DynamicContent.json"
import GuideSection from "./GuideSection";
import ExpectationSection from "./ExpectationSection";
import Carousel from "../index-sections/Carousel";
import TestimonialsConsRel from "./TestimonialRelationshipsCarousel";

function ConsciousRelationships() {
    // const [firstFocus, setFirstFocus] = React.useState(false);
    // const [lastFocus, setLastFocus] = React.useState(false);
    const [modal1, setModal1] = React.useState(false);
    // const coursesHeader = "The Masculine, The Feminine, And Conscious Relationships.";
    const indvHeader = "The Masculine, The Feminine, And Conscious Relationships.";
    const indvHeaderImg = "conscious_header2.jpeg";

    const centerStyle = {
        verticalAlign: "text-bottom",
        display: "table-cell",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        padding: "30px"
        // border: "1px solid #000000",
        // height: "inherit"
        // top: "50%",
        // msTransform: "translate(-50%, -50%)",
        // transform: "translate(-50%, -50%)"
        //    top: 50%;
        //   left: 50%;
        //   -ms-transform: translate(-50%, -50%);
        //   transform: translate(-50%, -50%);
    }

    const lightGrayBg = {
        backgroundColor: "lightgray",
        padding: "3px"
    }

    React.useEffect(() => {
        document.body.classList.add("landing-page");
        document.body.classList.add("sidebar-collapse");
        document.documentElement.classList.remove("nav-open");
        window.scrollTo(0, 0);
        document.body.scrollTop = 0;
        return function cleanup() {
            document.body.classList.remove("landing-page");
            document.body.classList.remove("sidebar-collapse");
        };
    }, []);
    return (
        <>
            <CoursesNavbar />
            <div className="wrapper">
                <CoursesPageHeader headerTitle={indvHeader} headerJpg={indvHeaderImg}/>
                <div className="section section-about-us">
                    <Container>
                        <div className="separator separator-primary"></div>
                        <div className="section-story-overview">
                            <Row>
                                <Col md="6">
                                    <div style={centerStyle} className="align-items-center">
                                        <h5 style={centerStyle}>Our relationships and interactions with other people serve as a mirror that allows us to see how far we have come on our journey of self development.
                                            <br /><br />
                                            As we become more aware of our actions and reactions in connections, we can begin to correct unwanted cycles and start attracting higher quality relationships and experiences.
                                            <br /><br />Each one of us consists of multiple layers and expressions (ego persona, emotional body, shadow). We also have a Core Feminine or Masculine energy along with an inner Masculine and Feminine energy.
                                        </h5>

                                    </div>
                                </Col>
                                <Col md="6">
                                    {/*<div*/}
                                    {/*    className="image-container image-left"*/}

                                    {/*    style={{*/}
                                    {/*        backgroundImage:*/}
                                    {/*            "url(" + require("assets/img/mirror_awareness.jpg").default + ")",*/}
                                    {/*        maxWidth: "100%",*/}
                                    {/*        backgroundSize: "100%",*/}
                                    {/*        backgroundRepeat: "no-repeat",*/}
                                    {/*        boxShadow: "none"*/}
                                    {/*    }}*/}
                                    {/*>*/}
                                    {/*</div>*/}
                                    <img src={awarenessImg} />
                                </Col>
                            </Row>
                            <Row>
                                <Col md="6">
                                    {/*<div*/}
                                    {/*    className="image-container"*/}

                                    {/*    style={{*/}
                                    {/*        backgroundImage:*/}
                                    {/*            "url(" + require("assets/img/heal_reflection.jpg").default + ")",*/}
                                    {/*        maxWidth: "100%",*/}
                                    {/*        backgroundSize: "100%",*/}
                                    {/*        backgroundRepeat: "no-repeat",*/}
                                    {/*        boxShadow: "none"*/}
                                    {/*    }}*/}
                                    {/*>*/}
                                    {/*</div>*/}
                                    <img src={reflectionImg} />
                                </Col>

                                <Col md="6">
                                    <div style={centerStyle} className="align-items-center" >
                                        <h5>It is through our relationships with others that we are able to see the condition of the connection between our own Masculine and Feminine energies.
                                        </h5>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col md="6">
                                    <div style={centerStyle} className="align-items-center">
                                        <h5>In this 3 week group coaching program we will learn more about the distorted, wounded, and Divine expressions/archetypes of Masculine/Feminine energy and how they are showing in both our relationships and in ourselves.

<br /> <br />Since we are all connected, and often embody collective archetypal expressions, we will notice that others are experiencing similar themes in their lives and relationships-- <br /> this is why this group format is so powerful.
                                        </h5>
                                    </div>
                                </Col>
                                <Col md="6">
                                    {/*<div*/}
                                    {/*    className="image-container image-left"*/}

                                    {/*    style={{*/}
                                    {/*        backgroundImage:*/}
                                    {/*            "url(" + require("assets/img/love_deeply.jpg").default + ")",*/}
                                    {/*        maxWidth: "100%",*/}
                                    {/*        backgroundSize: "100%",*/}
                                    {/*        backgroundRepeat: "no-repeat",*/}
                                    {/*        boxShadow: "none"*/}
                                    {/*    }}*/}
                                    {/*>*/}
                                    {/*</div>*/}
                                    <img src={loveDeeplyImg} />
                                </Col>
                            </Row>


                            <Row>
                                {/*<Col md="6">*/}
                                {/*    <div*/}
                                {/*        className="image-container"*/}
                                {/*        style={{*/}
                                {/*            backgroundImage:*/}
                                {/*                "url(" + require("assets/img/patterns.jpg").default + ")",*/}
                                {/*            maxWidth: "100%",*/}
                                {/*            backgroundSize: "contain",*/}
                                {/*            backgroundRepeat: "no-repeat",*/}
                                {/*            boxShadow: "none"*/}
                                {/*        }}*/}
                                {/*    >*/}
                                {/*    </div>*/}
                                {/*</Col>*/}
                                <Col>
                                    <p><img src={patternsImg} /> </p>
                                </Col>
                                <Col md="6">
                                    <div style={centerStyle} className="align-items-center">
                                        <h5>Together we will work to understand the blockages, loops, and lessons that are coming up in our connections so that we can work towards becoming fully embodied in the highest version of both our Masculine and Feminine.<br /><br /> This is how we manifest and build conscious partnerships with others who can truly see us at a soul level.
                                    </h5>
                                    </div>
                                </Col>
                            </Row>

                            <Row>
                                <div className="separator separator-primary"></div>
                            </Row>
                            <Row>
                                <Col>
                                    <div style={lightGrayBg} >
                                    <h4>Who is this program for? </h4>
                                    <h5>
                                    <ul>
                                        <li>Those who are looking to heal the repeating patterns in their relationships</li>
                                        <li>Those who are looking to know themselves more deeply so that they can experience more meaningful connections</li>
                                        <li>Those who are single or currently in a relationships</li>
                                        <li>Those in soul connections or who are wanting to call in soul connections</li>
                                        <li>Those on the path towards finding their purpose or who want to step more fully into their soul’s mission</li>
                                        <li>Those who are ready and willing to do the work to create conscious partnerships with others</li>
                                    </ul>
                                    </h5>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <h4>What others are saying about the course:</h4>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <TestimonialsConsRel />
                                </Col>
                            </Row>

                            <Row>
                                <div className="separator separator-primary"></div>
                            </Row>
                            <Row>
                                <Col>
                                    <h4>Each week we will meet via Zoom for a 90 minute call:</h4>
                                    <h5>
                                        <b>Week 1 & 2:</b><br/> These first two weeks we will look at the different layers of our own Masculine and Feminine energies and how they show up in both our expression of self as well as in our past and current relationships. We will end the session by figuring out what the patterns in our external relationships are trying to tell us about what we need to heal, integrate, or release.
                                        <br /><br />

                                        <b>Week 3:</b><br/>This last week we will look at the current relationship between our own Masculine and Feminine energies and identify ways for them to cooperate more harmoniously. We will come up with steps that we each can take to create an inner foundation that will encourage conscious connection with current or future partners.
                                        <br /><br />
                                        <div style={lightGrayBg}>
                                        In addition to the 90 minute weekly calls you will also have access to a private telegram group to connect, share, reflect, and ask questions.
                                        </div>
                                    </h5>
                                </Col>
                            </Row>
                            <Row><Col>
                                <div className="section section-contact-us text-center">
                                    <Container>
                                        <h2 className="title">Interested in the Program?</h2>
                                        <p className="">Get more info and sign up.</p>
                                        {/*<Row>*/}
                                        {/*    <Col className="text-center ml-auto mr-auto" lg="6" md="8">*/}
                                        {/*        <div className="send-button">*/}
                                        {/*            <Button*/}
                                        {/*                block*/}
                                        {/*                className="btn-round"*/}
                                        {/*                color="info"*/}
                                        {/*                href="#pablo"*/}
                                        {/*                onClick={(e) => e.preventDefault()}*/}
                                        {/*                size="lg"*/}
                                        {/*            >*/}
                                        {/*                Send me updates*/}
                                        {/*            </Button>*/}
                                        {/*        </div>*/}
                                        {/*    </Col>*/}
                                        {/*</Row>*/}
                                        <Row>
                                            <Col>
                                                <Button
                                                    block
                                                    className="btn-round"
                                                    color="info"
                                                    type="button"
                                                    href={DynamicContent.thewokenmind.relCoursePurchaseLink}
                                                    // onClick={(e) => e.preventDefault()}
                                                >Enroll in the Program
                                                </Button>
                                                {/*<Button*/}
                                                {/*    block*/}
                                                {/*    className="btn-round"*/}
                                                {/*    color="info"*/}
                                                {/*    onClick={() => setModal1(true)}*/}
                                                {/*>*/}
                                                {/*    Sign up!*/}
                                                {/*</Button>*/}
                                                {/*<Modal isOpen={modal1} toggle={() => setModal1(false)} scrollable={true}>*/}
                                                {/*    <div className="modal-header justify-content-center">*/}
                                                {/*        <button*/}
                                                {/*            className="close"*/}
                                                {/*            type="button"*/}
                                                {/*            onClick={() => setModal1(false)}*/}
                                                {/*        >*/}
                                                {/*            <i className="now-ui-icons ui-1_simple-remove"></i>*/}
                                                {/*        </button>*/}
                                                {/*        <h4 className="title title-up">Coaching/Services Terms</h4>*/}
                                                {/*    </div>*/}
                                                {/*    <ModalBody>*/}
                                                {/*        <p>*/}
                                                {/*            SERVICE<br />*/}

                                                {/*            The Woken Mind LLC (herein referred to as “Company”) agrees to provide individual/group coaching session identified in online commerce shopping cart. Client agrees to abide by all policies and procedures as outlined in this agreement as a condition of their participation in the coaching session.*/}

                                                {/*            <br />DISCLAIMER <br />*/}

                                                {/*            Laura (herein referred to as “Consultant”) and Company, is not an employee, agent, lawyer, doctor, manager, therapist, public relations or business manager, registered dietitian, or financial analyst, psychotherapist or accountant. Client understands that Consultant has not promised, shall not be obligated to and will not: (1) procure or attempt to procure employment or business or sales for Client; (2) perform any business management functions including but not limited to, accounting, tax or investment consulting, or advice with regard thereto; (3) act as a therapist providing psychoanalysis, psychological counseling or behavior therapy; (4) claim to heal or offer recovery for any illness or mental health issue you may have now, nor offer prevention of any illness or mental health issue that you may have in the future; (5) act as a public relations manager; (6) act as a publicist to procure any publicity, interviews, write-ups, features, television, print or digital media exposure for Client. Client understands that a relationship does not exist between the parties after the conclusion of the sessions. If the Parties continue their relationship, a separate agreement will be entered into.*/}

                                                {/*            The use of tarot/oracle cards during coaching sessions are for entertainment purposes only.*/}

                                                {/*            <br />EARNING DISCLAIMER <br />*/}

                                                {/*            There is no guarantee that you will earn any money using the exercises and ideas offered in coaching sessions. Your level of success in attaining results depends on the time you devote to your inner work, implementing the ideas and exercises mentioned, your finances, knowledge, and various skills. Since these factors differ according to individuals, we cannot guarantee your success or income level. Nor are we responsible for any of your actions*/}

                                                {/*            <br />REFUND POLICY <br />*/}

                                                {/*            No refunds after the session.*/}

                                                {/*            <br />RESCHEDULING/CANCELLATION POLICY<br />*/}

                                                {/*            Client is expected to show up for coaching/group coaching sessions on time. Emergencies within 24-hours are an exception.*/}
                                                {/*            <br />*/}
                                                {/*            To reschedule a session with more than 24 hours notice, send an email to thewokenmind@gmail.com. This must be done a minimum of 24 hours before the coaching session is scheduled to begin. If you cancel with less than 24 hours notice or no-show for a session, your coaching session will be forfeited without refund.*/}

                                                {/*            <br />CLIENT RESPONSIBILITY<br />*/}

                                                {/*            Client accepts and agrees that CLIENT is 100% responsible for their progress and results from sessions. Company makes no representations, warranties or guarantees verbally or in writing. Client understands that because of the nature of the coaching sessions and extent, the results experienced by each client may significantly vary.*/}

                                                {/*            <br />CONFIDENTIALITY<br />*/}

                                                {/*            The Company respects Client’s privacy and insists that Client respects the Company’s and Group Coaching Participants. Thus, consider this a mutual non-disclosure agreement. Any confidential information shared by Group Coaching Participants is confidential, Proprietary, and belongs solely and exclusively to the Participant who discloses it. Parties agree not to disclose, reveal or make use of any confidential information or any transactions, during discussions, on forums or otherwise. Client agrees not to use such confidential information in any manner other than in discussion with other Participants during Group Coaching. Confidential information includes, but is not limited to, information disclosed in connection with this Agreement, and shall not include information rightfully obtained from a third party. Both parties will keep Confidential Information in strictest confidence and shall use the best efforts to safeguard the Confidential Information and to protect it against disclosure, misuse, espionage, loss and theft. Client agrees not to violate the Company’s publicity or privacy rights. Furthermore Client will NOT reveal any information to a third party obtained in connection with this Agreement or Company’s direct or indirect dealing with Client including but not limited to: names, email addresses, third-party company titles or positions, phone numbers or addresses. Additionally, Consultant will not, at any time, either directly or indirectly, disclose confidential information to any third party. Further, by purchasing this product you agree that if you violate or display any likelihood of violating this session the Company and/or the Group Coaching participants will be entitled to injunctive relief to prohibit any such violations to protect against the harm of such violations.*/}

                                                {/*            <br />TERMINATION<br />*/}

                                                {/*            Company is committed to providing all clients with a positive experience. By purchasing this product, Client agrees that the Company may, at its sole discretion, terminate this Agreement, and limit, suspend, or terminate Client’s participation without refund if Client becomes disruptive to Company or Participants, impairs the participation of the other participants in the Group Coaching Sessions or upon violation of the terms as determined by Company. Client will still be liable to pay the total contract amount.*/}

                                                {/*            <br />INDEMNIFICATION<br />*/}

                                                {/*            Client shall defend, indemnify, and hold harmless Company, Company’s officers, employers, employees, contractors, directors, related entities, trustees, affiliates, and successors from and against any and all liabilities and expense whatsoever- including without limitation, claims, damages, judgments, awards, settlements, investigations, costs, attorney fees, and disbursements- which any of them may incur or become obligated to pay arising out of or resulting from the offering for sale, the sale, and/or use of the product(s), excluding, however, any such expenses and liabilities which may result from a breach of this Agreement or sole negligence or willful misconduct by Company. Client shall defend Company in any legal actions, regulatory actions, or the like arising from or related to this Agreement. Client recognizes and agrees that all of the Company’s shareholders, trustees, affiliates and successors shall not be held personally responsible or liable for any actions or representations of the Company. In consideration of and as part of my payment for the right to participate in Company’s services, the undersigned, my heirs, executors, administrators, successors and assigns do hereby release, waive, acquit, discharge, indemnify, defend, hold harmless and forever discharge Company and its subsidiaries, principals, directors, employees, agents, heirs, executors, administrators, successors, and assigns of and from all actions, causes of action, contracts, claims, suits, costs, demands and damages of whatever nature or kind in law or in a equity arising from my participation in the coaching/group coaching sessions*/}
                                                {/*        </p>*/}
                                                {/*    </ModalBody>*/}
                                                {/*    <div className="modal-footer">*/}
                                                {/*        /!*<Button*!/*/}
                                                {/*        /!*    block*!/*/}
                                                {/*        /!*    className="btn-round"*!/*/}
                                                {/*        /!*    color="info"*!/*/}
                                                {/*        /!*    type="button"*!/*/}
                                                {/*        /!*    to="/purchase-course"*!/*/}
                                                {/*        /!*    onClick={() => purchaseCourse()}*!/*/}
                                                {/*        /!*    >*!/*/}
                                                {/*        /!*    I Agree/Proceed*!/*/}
                                                {/*        /!*</Button>*!/*/}
                                                {/*        <Button*/}
                                                {/*            block*/}
                                                {/*            className="btn-round"*/}
                                                {/*            color="info"*/}
                                                {/*            type="button"*/}
                                                {/*            href={DynamicContent.thewokenmind.coursePurchaseLink}*/}
                                                {/*            // onClick={(e) => e.preventDefault()}*/}
                                                {/*        >I Agree. Continue to Course.*/}
                                                {/*        </Button>*/}
                                                {/*        /!*<Button*!/*/}
                                                {/*        /!*    className="btn-round"*!/*/}
                                                {/*        /!*    color="danger"*!/*/}
                                                {/*        /!*    type="button"*!/*/}
                                                {/*        /!*    onClick={() => setModal1(false)}*!/*/}
                                                {/*        /!*>*!/*/}
                                                {/*        /!*    Cancel*!/*/}
                                                {/*        /!*</Button>*!/*/}
                                                {/*    </div>*/}
                                                {/*</Modal>*/}
                                            </Col>
                                        </Row>
                                    </Container>
                                </div>
                            </Col></Row>
                        </div>
                    </Container>
                </div>

                <GuideSection/>
                <DarkFooter/>
            </div>
        </>
    );
}

export default ConsciousRelationships;
