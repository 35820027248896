import React from "react";

// reactstrap components
import {
    Button,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Container,
    Row,
    Col, Modal, ModalBody,
} from "reactstrap";

// core components
import ExamplesNavbar from "components/Navbars/ExamplesNavbar.js";
import LandingPageHeader from "components/Headers/LandingPageHeader.js";
import DefaultFooter from "components/Footers/DefaultFooter.js";
import CoursesPageHeader from "../../components/Headers/CoursesPageHeader";
import CoursesNavbar from "../../components/Navbars/CoursesNavbar";
import DarkFooter from "../../components/Footers/DarkFooter";
import DynamicContent from "../../assets/content/DynamicContent.json"
import GuideSection from "./GuideSection";

function Courses() {
    // const [firstFocus, setFirstFocus] = React.useState(false);
    // const [lastFocus, setLastFocus] = React.useState(false);
    const [modal1, setModal1] = React.useState(false);
    const coursesHeader = "The Masculine, The Feminine, And Manifestation.";
    React.useEffect(() => {
        document.body.classList.add("landing-page");
        document.body.classList.add("sidebar-collapse");
        document.documentElement.classList.remove("nav-open");
        window.scrollTo(0, 0);
        document.body.scrollTop = 0;
        return function cleanup() {
            document.body.classList.remove("landing-page");
            document.body.classList.remove("sidebar-collapse");
        };
    }, []);
    return (
        <>
            <CoursesNavbar/>
            <div className="wrapper">
                <CoursesPageHeader headerTitle={coursesHeader} headerJpg={"courses-header.jpeg"}/>
                <div className="section section-about-us">
                    <Container>
                        <Row>
                            <Col>
                                <img
                                    alt="..."
                                    className="rounded img-fluid"
                                    src={require("assets/img/fem_sym.jpg").default}
                                    style={{
                                        margin: "auto",
                                        display: "block"
                                    }}
                                ></img>
                            </Col>
                            <Col>
                                <img
                                    alt="..."
                                    className="rounded img-fluid"
                                    src={require("assets/img/mas_sym.jpg").default}
                                    style={{
                                        margin: "auto",
                                        display: "block",
                                        height: 175
                                    }}
                                ></img>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="ml-auto mr-auto text-center" md="6">

                                <h5 className="title">The Law of Attraction (Feminine Energy)</h5>
                                <p className="">
                                    Like attracts like
                                </p>
                            </Col>

                            <Col className="ml-auto mr-auto text-center" md="6">

                                <h5 className="title">Law of Action (Masculine Energy)</h5>
                                <p className="">
                                    Goals require action
                                </p>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="ml-auto mr-auto text-center" md="6">
                                <p className="">
                                    The two must be combined in order to create a union that
                                    allows us to take control of our lives.
                                </p>
                            </Col>
                        </Row>
                        <div className="separator separator-primary"></div>
                        <div className="section-story-overview">

                            <Row>
                                <Col md="6">
                                    <div
                                        className="image-container image-left"
                                        style={{
                                            backgroundImage:
                                                "url(" + require("assets/img/stone-wall.jpeg").default + ")",
                                        }}
                                    >
                                        <p className="blockquote blockquote-info-inv" style={{top: 125, left: 70}}>
                                            Why is it that so many artists, athletes, innovators, creators,
                                            entrepreneurs, are so
                                            magnetic? It doesn’t have to just do with their appearance, wealth, or
                                            status, these are all
                                            superficial traits- it is their internal union frequency.
                                        </p>
                                    </div>

                                </Col>
                                <Col md="5">
                                    <div
                                        className="image-container image-right"
                                        style={{
                                            backgroundImage:
                                                "url(" + require("assets/img/forest.jpeg").default + ")",
                                        }}
                                    ></div>
                                    <h3>
                                        Manifestation</h3>
                                    <p>
                                        It is through the process of manifestation that we can learn how to oscillate
                                        between these
                                        two Laws of the Universe-- bringing more balance to our Masculine and Feminine
                                        energies.
                                    </p>
                                    <p>
                                        Birthing creative inspiration and then bringing it into fruition in the physical
                                        is an excellent
                                        way to foster union between the Masculine and Feminine within us. Every time we
                                        create
                                        or work to master a craft it is almost like our Masculine and Feminine energies
                                        are dating;
                                        they are mingling and getting to know each other at a deeper level.
                                    </p>
                                </Col>
                            </Row>
                            <Row>
                                <Col md="5">
                                    <div>
                                        <p>
                                            Using manifestation as a means to work towards a purpose or goal can bring
                                            in both higher
                                            frequency relationships and opportunities into our lives.
                                        </p>
                                    </div>
                                    <p>
                                        A clear understanding and application of the Laws of the Universe and
                                        Masculine/Feminine energy transforms us into the co-creators of our lives. No
                                        more sitting
                                        back and waiting for things to happen-- you are the one in charge of your story!
                                    </p>
                                    <p>&nbsp;</p>
                                    <p>
                                        <h4>About this Course:</h4>
                                    </p>
                                    <p>
                                        In this 3 week group coaching program we will go deep into the exploration of
                                        using inner union as a means to manifest.
                                    </p>
                                </Col>
                                <Col md="7">
                                    <div>
                                        <div
                                            className="image-container"
                                            style={{
                                                backgroundImage:
                                                    "url(" + require("assets/img/rose.jpeg").default + ")",
                                                top: 0
                                            }}
                                        ></div>
                                        <p className="blockquote blockquote-info-hi" style={{top: 125, left: -20}}>
                                            The more we practice bringing our own energies into union the more we will
                                            see
                                            this
                                            balance reflected back to us in our external reality.
                                        </p>

                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <div className="separator separator-primary"></div>
                            </Row>
                            <Row>
                                <Col>
                                    <p>
                                        <b>Week 1:</b><br/> Connecting to the Feminine- This week we will learn how to
                                        access the Feminine and retrieve creative inspiration from our Higher Self. We
                                        will activate the skills of play, rest, and fluidity; opening ourselves so that
                                        we can channel the Divine Feminine.
                                    </p><p>


                                    <b>Week 2:</b><br/>Unpacking the Masculine- This week we will focus on strengthening
                                    our Masculine Energy so that he can serve as a safe container for the Feminine. We
                                    will work on releasing old patterns, passing karmic tests, and letting go of
                                    self-sabotaging behaviors.
                                </p><p>

                                    <b>Week 3:</b> <br/>Cooperation/Union- This last week will be all about clearing
                                    residual doubts (cold feet) from our Masculine Energy and confidently taking those
                                    initial small steps towards action; allowing our Feminine Energy to meet us halfway-
                                    the union of the spiritual and physical.
                                </p>
                                    <p>
                                        <ul>
                                            <li>Each week we will have a 60-90 minute group call on Zoom and weekly
                                                activities to apply what we’ve learned.
                                            </li>
                                            <li>You will be given access to a weekly activity forum to discuss the
                                                activities, ask questions, and share with the group.
                                            </li>
                                            <li>On-going membership for a private forum after the program, providing
                                                continued support from me and other members.
                                            </li>
                                        </ul>
                                    </p>
                                </Col>
                            </Row>
                            <Row><Col>
                                <div className="section section-contact-us text-center">
                                    <Container>
                                        <h2 className="title">Interested in the Program?</h2>
                                        <p className="">Get more info and sign up.</p>
                                        {/*<Row>*/}
                                        {/*    <Col className="text-center ml-auto mr-auto" lg="6" md="8">*/}
                                        {/*        <div className="send-button">*/}
                                        {/*            <Button*/}
                                        {/*                block*/}
                                        {/*                className="btn-round"*/}
                                        {/*                color="info"*/}
                                        {/*                href="#pablo"*/}
                                        {/*                onClick={(e) => e.preventDefault()}*/}
                                        {/*                size="lg"*/}
                                        {/*            >*/}
                                        {/*                Send me updates*/}
                                        {/*            </Button>*/}
                                        {/*        </div>*/}
                                        {/*    </Col>*/}
                                        {/*</Row>*/}
                                        <Row>
                                            <Col>
                                                <Button
                                                    block
                                                    className="btn-round"
                                                    color="info"
                                                    type="button"
                                                    href={DynamicContent.thewokenmind.coursePurchaseLink}
                                                    // onClick={(e) => e.preventDefault()}
                                                >Enroll in the Program
                                                </Button>
                                                {/*<Button*/}
                                                {/*    block*/}
                                                {/*    className="btn-round"*/}
                                                {/*    color="info"*/}
                                                {/*    onClick={() => setModal1(true)}*/}
                                                {/*>*/}
                                                {/*    Sign up!*/}
                                                {/*</Button>*/}
                                                {/*<Modal isOpen={modal1} toggle={() => setModal1(false)} scrollable={true}>*/}
                                                {/*    <div className="modal-header justify-content-center">*/}
                                                {/*        <button*/}
                                                {/*            className="close"*/}
                                                {/*            type="button"*/}
                                                {/*            onClick={() => setModal1(false)}*/}
                                                {/*        >*/}
                                                {/*            <i className="now-ui-icons ui-1_simple-remove"></i>*/}
                                                {/*        </button>*/}
                                                {/*        <h4 className="title title-up">Coaching/Services Terms</h4>*/}
                                                {/*    </div>*/}
                                                {/*    <ModalBody>*/}
                                                {/*        <p>*/}
                                                {/*            SERVICE<br />*/}

                                                {/*            The Woken Mind LLC (herein referred to as “Company”) agrees to provide individual/group coaching session identified in online commerce shopping cart. Client agrees to abide by all policies and procedures as outlined in this agreement as a condition of their participation in the coaching session.*/}

                                                {/*            <br />DISCLAIMER <br />*/}

                                                {/*            Laura (herein referred to as “Consultant”) and Company, is not an employee, agent, lawyer, doctor, manager, therapist, public relations or business manager, registered dietitian, or financial analyst, psychotherapist or accountant. Client understands that Consultant has not promised, shall not be obligated to and will not: (1) procure or attempt to procure employment or business or sales for Client; (2) perform any business management functions including but not limited to, accounting, tax or investment consulting, or advice with regard thereto; (3) act as a therapist providing psychoanalysis, psychological counseling or behavior therapy; (4) claim to heal or offer recovery for any illness or mental health issue you may have now, nor offer prevention of any illness or mental health issue that you may have in the future; (5) act as a public relations manager; (6) act as a publicist to procure any publicity, interviews, write-ups, features, television, print or digital media exposure for Client. Client understands that a relationship does not exist between the parties after the conclusion of the sessions. If the Parties continue their relationship, a separate agreement will be entered into.*/}

                                                {/*            The use of tarot/oracle cards during coaching sessions are for entertainment purposes only.*/}

                                                {/*            <br />EARNING DISCLAIMER <br />*/}

                                                {/*            There is no guarantee that you will earn any money using the exercises and ideas offered in coaching sessions. Your level of success in attaining results depends on the time you devote to your inner work, implementing the ideas and exercises mentioned, your finances, knowledge, and various skills. Since these factors differ according to individuals, we cannot guarantee your success or income level. Nor are we responsible for any of your actions*/}

                                                {/*            <br />REFUND POLICY <br />*/}

                                                {/*            No refunds after the session.*/}

                                                {/*            <br />RESCHEDULING/CANCELLATION POLICY<br />*/}

                                                {/*            Client is expected to show up for coaching/group coaching sessions on time. Emergencies within 24-hours are an exception.*/}
                                                {/*            <br />*/}
                                                {/*            To reschedule a session with more than 24 hours notice, send an email to thewokenmind@gmail.com. This must be done a minimum of 24 hours before the coaching session is scheduled to begin. If you cancel with less than 24 hours notice or no-show for a session, your coaching session will be forfeited without refund.*/}

                                                {/*            <br />CLIENT RESPONSIBILITY<br />*/}

                                                {/*            Client accepts and agrees that CLIENT is 100% responsible for their progress and results from sessions. Company makes no representations, warranties or guarantees verbally or in writing. Client understands that because of the nature of the coaching sessions and extent, the results experienced by each client may significantly vary.*/}

                                                {/*            <br />CONFIDENTIALITY<br />*/}

                                                {/*            The Company respects Client’s privacy and insists that Client respects the Company’s and Group Coaching Participants. Thus, consider this a mutual non-disclosure agreement. Any confidential information shared by Group Coaching Participants is confidential, Proprietary, and belongs solely and exclusively to the Participant who discloses it. Parties agree not to disclose, reveal or make use of any confidential information or any transactions, during discussions, on forums or otherwise. Client agrees not to use such confidential information in any manner other than in discussion with other Participants during Group Coaching. Confidential information includes, but is not limited to, information disclosed in connection with this Agreement, and shall not include information rightfully obtained from a third party. Both parties will keep Confidential Information in strictest confidence and shall use the best efforts to safeguard the Confidential Information and to protect it against disclosure, misuse, espionage, loss and theft. Client agrees not to violate the Company’s publicity or privacy rights. Furthermore Client will NOT reveal any information to a third party obtained in connection with this Agreement or Company’s direct or indirect dealing with Client including but not limited to: names, email addresses, third-party company titles or positions, phone numbers or addresses. Additionally, Consultant will not, at any time, either directly or indirectly, disclose confidential information to any third party. Further, by purchasing this product you agree that if you violate or display any likelihood of violating this session the Company and/or the Group Coaching participants will be entitled to injunctive relief to prohibit any such violations to protect against the harm of such violations.*/}

                                                {/*            <br />TERMINATION<br />*/}

                                                {/*            Company is committed to providing all clients with a positive experience. By purchasing this product, Client agrees that the Company may, at its sole discretion, terminate this Agreement, and limit, suspend, or terminate Client’s participation without refund if Client becomes disruptive to Company or Participants, impairs the participation of the other participants in the Group Coaching Sessions or upon violation of the terms as determined by Company. Client will still be liable to pay the total contract amount.*/}

                                                {/*            <br />INDEMNIFICATION<br />*/}

                                                {/*            Client shall defend, indemnify, and hold harmless Company, Company’s officers, employers, employees, contractors, directors, related entities, trustees, affiliates, and successors from and against any and all liabilities and expense whatsoever- including without limitation, claims, damages, judgments, awards, settlements, investigations, costs, attorney fees, and disbursements- which any of them may incur or become obligated to pay arising out of or resulting from the offering for sale, the sale, and/or use of the product(s), excluding, however, any such expenses and liabilities which may result from a breach of this Agreement or sole negligence or willful misconduct by Company. Client shall defend Company in any legal actions, regulatory actions, or the like arising from or related to this Agreement. Client recognizes and agrees that all of the Company’s shareholders, trustees, affiliates and successors shall not be held personally responsible or liable for any actions or representations of the Company. In consideration of and as part of my payment for the right to participate in Company’s services, the undersigned, my heirs, executors, administrators, successors and assigns do hereby release, waive, acquit, discharge, indemnify, defend, hold harmless and forever discharge Company and its subsidiaries, principals, directors, employees, agents, heirs, executors, administrators, successors, and assigns of and from all actions, causes of action, contracts, claims, suits, costs, demands and damages of whatever nature or kind in law or in a equity arising from my participation in the coaching/group coaching sessions*/}
                                                {/*        </p>*/}
                                                {/*    </ModalBody>*/}
                                                {/*    <div className="modal-footer">*/}
                                                {/*        /!*<Button*!/*/}
                                                {/*        /!*    block*!/*/}
                                                {/*        /!*    className="btn-round"*!/*/}
                                                {/*        /!*    color="info"*!/*/}
                                                {/*        /!*    type="button"*!/*/}
                                                {/*        /!*    to="/purchase-course"*!/*/}
                                                {/*        /!*    onClick={() => purchaseCourse()}*!/*/}
                                                {/*        /!*    >*!/*/}
                                                {/*        /!*    I Agree/Proceed*!/*/}
                                                {/*        /!*</Button>*!/*/}
                                                {/*        <Button*/}
                                                {/*            block*/}
                                                {/*            className="btn-round"*/}
                                                {/*            color="info"*/}
                                                {/*            type="button"*/}
                                                {/*            href={DynamicContent.thewokenmind.coursePurchaseLink}*/}
                                                {/*            // onClick={(e) => e.preventDefault()}*/}
                                                {/*        >I Agree. Continue to Course.*/}
                                                {/*        </Button>*/}
                                                {/*        /!*<Button*!/*/}
                                                {/*        /!*    className="btn-round"*!/*/}
                                                {/*        /!*    color="danger"*!/*/}
                                                {/*        /!*    type="button"*!/*/}
                                                {/*        /!*    onClick={() => setModal1(false)}*!/*/}
                                                {/*        /!*>*!/*/}
                                                {/*        /!*    Cancel*!/*/}
                                                {/*        /!*</Button>*!/*/}
                                                {/*    </div>*/}
                                                {/*</Modal>*/}
                                            </Col>
                                        </Row>
                                    </Container>
                                </div>
                            </Col></Row>
                        </div>
                    </Container>
                </div>

                <GuideSection/>
                <DarkFooter/>
            </div>
        </>
    );
}

export default Courses;
