import React from "react";

// reactstrap components
import { Carousel, CarouselItem, CarouselIndicators } from "reactstrap";

const testimonials = require("../../assets/content/Testimonials.json")["consciousRelationships"];

// core components
const olditems = [
    {
        src: require("assets/img/bg1.jpg"),
        altText: "Nature, United States",
        caption: "Nature, United States"
    },
    {
        src: require("assets/img/bg3.jpg"),
        altText: "Somewhere Beyond, United States",
        caption: "Somewhere Beyond, United States"
    },
    {
        src: require("assets/img/bg4.jpg"),
        altText: "Yellowstone National Park, United States",
        caption: "Yellowstone National Park, United States"
    }
];

function TestimonialsConsRel(){
    const [activeIndex, setActiveIndex] = React.useState(0);
    const [animating, setAnimating] = React.useState(false);

    const replaceNewlines = (extractedText) => {
        return extractedText;//.replace("\n", "<br />") ;
    }

    const onExiting = () => {
        setAnimating(true);
    };
    const onExited = () => {
        setAnimating(false);
    };
    const next = () => {
        if (animating) return;
        const nextIndex = activeIndex === testimonials.length - 1 ? 0 : activeIndex + 1;
        setActiveIndex(nextIndex);
    };
    const previous = () => {
        if (animating) return;
        const nextIndex = activeIndex === 0 ? testimonials.length - 1 : activeIndex - 1;
        setActiveIndex(nextIndex);
    };
    const goToIndex = newIndex => {
        if (animating) return;
        setActiveIndex(newIndex);
    };
    return (
        <>
            <Carousel activeIndex={activeIndex} next={next} previous={previous}>
                <CarouselIndicators
                    items={testimonials}
                    activeIndex={activeIndex}
                    onClickHandler={goToIndex}
                />
                {testimonials.map(item => {
                    return (
                        <CarouselItem
                            onExiting={onExiting}
                            onExited={onExited}
                            key={item.poster}
                        >
                            <div style={{
                                justifyContent: "center",
                                alignItems: "center",
                                textAlign: "center",
                                whiteSpace: "pre-line"
                            }}>
                            {/*    <h5>{item.content}</h5>*/}
                            {/*    /!*</div>*!/*/}
                            {/*    <div><h6></h6></div>*/}
                            {/*</div>*/}

                            <p className="description">{replaceNewlines(item.content)}</p>
                            <p className="category text-info">By: {item.poster}</p>
                                <br />
                            {/*<img src={item.src} alt={item.altText} />*/}
                            {/*<div className="carousel-caption d-none d-md-block">*/}
                            </div>
                        </CarouselItem>
                    );
                })}
                <a
                    className="carousel-control-prev"
                    data-slide="prev"
                    // href="#pablo"
                    onClick={e => {
                        e.preventDefault();
                        previous();
                    }}
                    role="button"
                >
                    <i className="now-ui-icons arrows-1_minimal-left"></i>
                </a>
                <a
                    className="carousel-control-next"
                    data-slide="next"
                    // href="#pablo"
                    onClick={e => {
                        e.preventDefault();
                        next();
                    }}
                    role="button"
                >
                    <i className="now-ui-icons arrows-1_minimal-right"></i>
                </a>
            </Carousel>
        </>
    );
}

export default TestimonialsConsRel;
