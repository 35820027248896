/*eslint-disable*/
import React from "react";

// reactstrap components
import { Container } from "reactstrap";

function DarkFooter() {
  return (
    <footer className="footer" data-background-color="black">
      <Container>
        <nav>
          <ul>
            <li>
              &nbsp;
            </li>
            <li>
              &nbsp;
            </li>
            <li>
              &nbsp;
            </li>
          </ul>
        </nav>
        <div className="copyright" id="copyright">
          © {new Date().getFullYear()},
          {" "}
          <a href="https://thewokenmind.com">
            The Woken Mind
          </a>
          .
          A {" "}
          <a
              href="https://be3.dev/"
              target="_blank"
          >
            Be3.dev
          </a>
          &nbsp;project.
        </div>
      </Container>
    </footer>
  );
}

export default DarkFooter;
