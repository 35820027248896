import React from "react";

// reactstrap components
import {Button, Col, Container, Row,} from "reactstrap";
import masLionImg from "../../assets/img/masc_lion.jpg";
import telegramIcon from "../../assets/img/telegram_logo.svg";


// core components
import CoursesPageHeader from "../../components/Headers/CoursesPageHeader";
import CoursesNavbar from "../../components/Navbars/CoursesNavbar";
import DarkFooter from "../../components/Footers/DarkFooter";
import DynamicContent from "../../assets/content/DynamicContent.json"
import GuideSection from "./GuideSection";

function MasculineWorkshop() {

    const indvHeader = "Grounding the Divine Masculine From Within.";
    const indvHeaderImg = "mtn_header.jpeg";

    const centerStyle = {
        verticalAlign: "text-bottom",
        display: "table-cell",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        padding: "30px"
    }

    const smallerFont = {
        fontSize: "93%"
    }

    const lightGrayBg = {
        backgroundColor: "lightgray",
        padding: "3px",
        verticalAlign: "text-bottom",
        display: "table-cell",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center"
    }

    const purchaseButton = function (btnAmount, coupleIndv, courseOption) {
        //coupleIndv 1 for individual, 2 for couples
        //course option ascending from top
        let btnId = "btn" + btnAmount;
        let purchaseUrl = DynamicContent.thewokenmind.masWorkshopPurchaseLink
        //"https://calendly.com/thewokenmind/coaching-pre-screen?a3=" + coupleIndv + "&a4=" + courseOption;
        return <a
            href={purchaseUrl}>
            <button className="btn-round btn-sm" id={btnId}><i
                className="fas fa-dollar-sign"/>{btnAmount}</button>
        </a>
    };

    React.useEffect(() => {
        document.body.classList.add("landing-page");
        document.body.classList.add("sidebar-collapse");
        document.documentElement.classList.remove("nav-open");
        window.scrollTo(0, 0);
        document.body.scrollTop = 0;
        return function cleanup() {
            document.body.classList.remove("landing-page");
            document.body.classList.remove("sidebar-collapse");
        };
    }, []);
    return (
        <>
            <CoursesNavbar/>
            <div className="wrapper">
                <CoursesPageHeader headerTitle={indvHeader} headerJpg={indvHeaderImg}/>
                <div className="section section-about-us">
                    <Container>
                        <div className="separator separator-primary"></div>
                        <div className="section-story-overview">
                            <Row>
                                <Col md="6">
                                    <div style={centerStyle} className="align-items-center">
                                        <h5 style={centerStyle}>
                                            <h4>A<br/>2 hour workshop</h4><h5>Live on September 21 @5PM
                                            PT</h5> for Feminines who are working on strengthening their inner
                                            Masculine energy.
                                            <br/><br/>
                                            In this workshop you will learn more about what your inner Masculine energy
                                            is and what it looks like when it is applied to life experiences and
                                            relationships. We will also tap into what is occurring with the collective
                                            Masculine Energy and how the Feminine can assist with the grounding of
                                            Divine Masculine Energy here on earth through her own process of healing,
                                            integration, and embodiment.
                                            <br/>
                                            <br/>
                                            A private telegram group will be available from the time of registration.  On the 21st there will be a presentation, exercises, and a Q&A portion at the end.
                                        </h5>

                                    </div>
                                </Col>
                                <Col md="6">
                                    <img src={masLionImg} className="img-raised"/>
                                </Col>
                            </Row>
                            <Row>
                                <div className="separator separator-primary"></div>
                            </Row>
                            <Row>
                                <Col><br/></Col>
                            </Row>
                            <Row>
                                <Col>
                                    <div style={lightGrayBg}>
                                        <h5>
                                            <p className="category text-info">
                                                Who is this workshop for?
                                            </p>
                                            <p style={smallerFont}>
                                                Feminines who desire to strengthen their Masculine Energy.<br />
                                                The information in the workshop will be relevant for those who are currently single and those in relationships.
                                            </p>
                                            <p className="category text-info">
                                                Where will it take place?
                                            </p>
                                            <p style={smallerFont}>
                                                The workshop will be live on Zoom.
                                            </p>
                                            <p className="category text-info">
                                                Do I Need To Attend Live?
                                            </p>
                                            <p style={smallerFont}>
                                                I do ask that everyone attend at least a portion of the workshop if possible. It is the collective energy that each person brings to our gathering that makes it a powerful community experience, being there live will allow you to get the most out of the workshop. The workshop will be recorded and all attendees will receive access to the recording within 24 hours after it ends.
                                                After purchasing, please send me an email at <a href={DynamicContent.thewokenmind.email} style={{ color: "darkblue" }}>thewokenmind@gmail.com</a> if you have any questions or concerns or if you won't be able to attend live.
                                            </p>
                                        </h5>
                                    </div>
                                </Col>
                            </Row>

                            <Row>
                                <div className="separator separator-primary"></div>
                            </Row>
                            <Row>
                                <Col style={centerStyle}>
                                    <h5>Everyone who registers will receive access to a private telegram group where I will be answering questions and facilitating discussions. Register early so that we can get the conversation started!</h5>
                                    <img src={telegramIcon} alt={'telegram icon'} height={75}/>
                                </Col>
                            </Row>
                            <Row>
                                <Col style={centerStyle} className={'text-danger'}>
                                    <h5>When purchasing, please use an email that you check regularly so that you can receive information about the Telegram group, how to access the recording, and the login for our Zoom session.</h5>
                                </Col>
                            </Row>
                            <Row>
                                <Col style={centerStyle}>
                                    <h5><br/>
                                        Early Price (ends September 17) <br/>{purchaseButton(79, 2, 2)}
                                        <br/><br/>
                                        Regular Price <br/>{purchaseButton(99, 2, 2)}
                                    </h5>
                                </Col>
                            </Row>
                            <Row><Col>
                                <div className="section section-contact-us text-center">
                                    <Container>
                                        <h2 className="title"></h2>
                                        <p style={{
                                            fontSize: "150%"
                                        }}>
                                            Registration closes at 5pm PST on September 20th</p>
                                        <Row>
                                            <Col>
                                                <Button
                                                    block
                                                    className="btn-round"
                                                    color="info"
                                                    type="button"
                                                    href={DynamicContent.thewokenmind.masWorkshopPurchaseLink}
                                                >Reserve Your Spot
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Container>
                                </div>
                            </Col></Row>
                        </div>
                    </Container>
                </div>

                <GuideSection/>
                <DarkFooter/>
            </div>
        </>
    );
}

export default MasculineWorkshop;
