import React from "react";

// reactstrap components
import {
    Button,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Container,
    Row,
    Col, Modal, ModalBody,
} from "reactstrap";

// core components
import ExamplesNavbar from "components/Navbars/ExamplesNavbar.js";
import LandingPageHeader from "components/Headers/LandingPageHeader.js";
import DefaultFooter from "components/Footers/DefaultFooter.js";
import CoursesPageHeader from "../../components/Headers/CoursesPageHeader";
import CoursesNavbar from "../../components/Navbars/CoursesNavbar";
import DarkFooter from "../../components/Footers/DarkFooter";
import DynamicContent from "../../assets/content/DynamicContent.json"
import GuideSection from "./GuideSection";
import ExpectationSection from "./ExpectationSection";

function Individuals() {
    const [modal1, setModal1] = React.useState(false);
    const indvHeader = "Individual One on One Sessions.";
    const indvHeaderImg = "fire.jpg";

    React.useEffect(() => {
        document.body.classList.add("landing-page");
        document.body.classList.add("sidebar-collapse");
        document.documentElement.classList.remove("nav-open");
        window.scrollTo(0, 0);
        document.body.scrollTop = 0;
        return function cleanup() {
            document.body.classList.remove("landing-page");
            document.body.classList.remove("sidebar-collapse");
        };
    }, []);
    return (
        <>
            <CoursesNavbar/>
            <div className="wrapper">
                <CoursesPageHeader headerTitle={indvHeader} headerJpg={indvHeaderImg}/>
                <div className="section section-about-us">
                    <Container>
                        <div className="separator separator-primary"></div>
                        <div className="section-story-overview">
                            <Row>
                                <Col md="6"><div
                                    className="image-container"
                                    style={{
                                        backgroundImage:
                                            "url(" + require("assets/img/masc_femn_m.jpg").default + ")",
                                        height: 768,
                                        maxWidth: "100%",
                                        backgroundSize: "contain, contain",
                                        backgroundRepeat: "no-repeat",
                                        alignContent: "center",
                                        boxShadow: "none"
                                    }}
                                ></div></Col>
                                <Col md="6"><div
                                    className="image-container"
                                    style={{
                                        // backgroundImage:
                                        //     "-webkit-image-set( url(" + require("assets/img/masc_femn_s.jpg").default +
                                        //     ") 1x, url(" + require("assets/img/masc_femn_f.jpg").default + ") 2x )",
                                        backgroundImage:
                                            "url(" + require("assets/img/masc_femn_f.jpg").default + ")",
                                        // width: "782",
                                        height: 768,
                                        maxWidth: "100%",
                                        // maxHeight: 1024,
                                        // maxHeight: "100%",
                                        backgroundSize: "contain, contain",
                                        //backgroundSize: "auto", "100%";
                                        backgroundRepeat: "no-repeat",
                                        alignContent: "center",
                                        // boxSizing: "100%",
                                        boxShadow: "none"
                                    }}
                                ></div></Col>
                            </Row>
                            <Row>
                                <Col>
                                    <div>
                                        <h4 align="center">I am here to assist you in the process of strengthening your own intuitive abilities in order to unlock your opportunity to act as the co-creator of your life. </h4>
                                    </div>

                                </Col>
                            </Row>
                            <Row>
                                <div className="separator separator-primary">&nbsp;</div>
                            </Row>
                            <Row>
                                <Col md="6">
                                    <div>
                                        <p>Often, we find ourselves stuck in old, limiting patterns that continue to replay in our subconscious mind. The recognition of these patterns is actually a gift that comes from our Higher Self, because once we can acknowledge that we are in a loop we can then work to break down the barriers that are blocking us from aligning with the true will of our soul.
                                        </p><p>
                                            Utilize your life experiences as the keys to unlock the lessons that will result in the mastering of your will.
                                    </p><p>
                                            Live a life full of purpose, love, abundance, and joy. This is our birthright and the divine plan of the Universe.
                                        </p>
                                    </div>
                                </Col>
                                <Col md="6">
                                    <div
                                        className="image-container image-left"

                                        style={{
                                            backgroundImage:
                                                "url(" + require("assets/img/stuck_karmic.jpg").default + ")",
                                            maxWidth: "100%",
                                            backgroundSize: "100%",
                                            backgroundRepeat: "no-repeat",
                                            boxShadow: "none"
                                        }}
                                    >
                                    </div>
                                </Col>
                            </Row>

                            <Row>
                                <Col md="6">
                                    <div
                                        className="image-container image-right"
                                        style={{
                                            backgroundImage:
                                                "url(" + require("assets/img/balance_energies.jpg").default + ")",
                                            maxWidth: "100%",
                                            backgroundSize: "100%",
                                            backgroundRepeat: "no-repeat",
                                            boxShadow: "none"
                                        }}
                                    >
                                    </div>
                                </Col>
                                <Col md="6">
                                    <div>
                                        <p>Masculine and Feminine energy is the framework for duality. You can see it as the intricate balance between the heart and head; soul and ego; spiritual and physical; or right and left hemispheres of the brain. When these energies are in conflict with each other within ourselves this will also show up in our external reality.
                                        </p><p>
                                        Through both self-reflection and reflecting on our relationships with others, we can gain clarity on what we need to work on to bring healing, awareness, and balance to how our Masculine and Feminine energies interact with each other.
                                    </p>
                                    </div>
                                </Col>

                            </Row>
                            <Row>

                                <Col md="4"><p>
                                    The key is to come into an inner union (the alchemical marriage) with self so that you can release co-dependency and step fully into your authenticity, sovereignty, and purpose.
                                </p>
                                </Col>
                                <Col md="6">
                                    <div
                                        className="image-container image-left"
                                        style={{
                                            backgroundImage:
                                                "url(" + require("assets/img/external_internal_world.jpg").default + ")",
                                            maxWidth: "100%",
                                            backgroundSize: "100%",
                                            backgroundRepeat: "no-repeat",
                                            boxShadow: "none"
                                        }}
                                    >
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <div className="separator separator-primary">&nbsp;</div>
                            </Row>
<Row>
        <Col>
                <div
                    className="image-container"
                    style={{
                        backgroundImage:
                            "url(" + require("assets/img/what_to_expect.jpg").default + ")",
                        top: 0,
                        width: "auto",
                        maxWidth: "100%",
                        alignContent: "center",
                        backgroundSize: "100%",
                        backgroundRepeat: "no-repeat",
                        boxShadow: "none"
                    }}
                ></div>

        </Col>
</Row>

                            <Row>
                                <Col>
                                    <div className="separator separator-primary">&nbsp;</div></Col>
                            </Row>

                            <Row>
                                <Col>
                                    <p>I offer the opportunity to connect to your higher self, bypassing the chatter and repeating patterns of your lower ego, by serving as a mirror. My goal is to help you strengthen your own intuitive abilities so that you can begin to trust in both yourself and the laws of the Universe.
                                    </p><p>
                                        My services focus on using your reflections on life experiences and relationships as a means to make sense of the spiritual lessons that you are currently working on. Together we will come up with goals, exercises, and affirmations that will help you ascend to a higher level of consciousness and state of being.
                                </p><p>
                                        If you desire, I will use oracle cards to help process what comes up in our discussions and to access any additional messages that your soul wishes to share.
                                </p><p>
                                        With my intuitive guidance, and a casual give and take of information, through conversation, we will work to make sense of all that is hidden within your soul.
                                    </p>
                                </Col>
                            </Row>

                            <ExpectationSection />

                            <Row>
                                <Col><div className="separator separator-primary">&nbsp;</div></Col>

                            </Row>
                            <Row>
                                <Col>
                                    <div>
                                        <h3 align="center">Coaching Contract</h3>
                                        <p align="center">    At checkout, before purchasing, click on the words "terms and conditions" to read and review.
                                        </p>
                                    </div>
                                </Col>
                            </Row>
                            <Row><Col>
                                <div className="section section-contact-us text-center">
                                    <Container>
                                        <Row>
                                            <Col>
                                                <Button
                                                    block
                                                    className="btn-round"
                                                    color="info"
                                                    type="button"
                                                    href={DynamicContent.thewokenmind.indvSessionLink}
                                                >Book a Session
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Container>
                                </div>
                            </Col></Row>
                        </div>

                    </Container>
                </div>


                <GuideSection/>
                <DarkFooter/>
            </div>
        </>
    );
}

export default Individuals;
