const {Container, Row, Col, Button} = require("reactstrap");
const DynamicContent = require("../../assets/content/DynamicContent.json");

function GuideSection() {
    return <div className="section section-team text-center">
        <Container>
            <h3 className="title">Your Guide</h3>
            <div className="team">
                <Row>
                    <Col md="12">
                        <div className="team-player">
                            <img
                                alt="..."
                                className="rounded-circle circle img-fluid img-raised"
                                src={require("assets/img/profile_2.jpg").default}
                            ></img>
                            <h4 className="title">Laura Grant</h4>
                            <p className="category text-info">Masculine/Feminine Energy Coach</p>
                            <p className="">
                                Laura is a Spiritual Teacher and Intuitive Coach who utilizes a combination
                                of her background in music,
                                business, education, and studies in spiritual science to provide service for
                                humanity. She is a channel
                                for the Spirit world who is deeply connected to her Ancestors and Ancient
                                Wisdom. Her professional
                                studies in early childhood development and the power of play, along with her
                                love for the arts,
                                create a unique framework for intuitive messages to flow. Through her own
                                personal initiation process
                                she has come to a level of spiritual understanding that can be
                                conceptualized in an organized manner
                                that is ideal for supporting others with balancing their Masculine/Feminine
                                energies, clearing
                                karmic/ancestral patterns, and elevating their frequency. Laura is a life
                                long learner who has
                                accumulated knowledge from a wide array of great thinkers who have dared to
                                share their innovative,
                                revelations with the public- but more importantly, her wisdom comes from
                                integrating and applying
                                this knowledge to her own journey. She is an awakened feminine who is
                                inspired by a higher power
                                and dedicated to assisting with the ongoing evolution of the human
                                collective.
                            </p>
                            <Button
                                className="btn-icon btn-round"
                                color="info"
                                href={DynamicContent.thewokenmind.youtubeUrl}
                                // onClick={(e) => e.preventDefault()}
                            >
                                <i className="fab fa-youtube"></i>
                            </Button>
                            <Button
                                className="btn-icon btn-round"
                                color="info"
                                href={DynamicContent.thewokenmind.instagramUrl}
                                // onClick={(e) => e.preventDefault()}
                            >
                                <i className="fab fa-instagram"></i>
                            </Button>
                        </div>
                    </Col>
                </Row>
            </div>
        </Container>
    </div>
}

export default GuideSection;